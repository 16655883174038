export type Translations = typeof defaultTranslations
export type TranslationKeys = keyof Translations

const translate = (key: TranslationKeys, ...params: string[]) => {
  let t = translations[key] || defaultTranslations[key]
  if (params) {
    for (let i = 0; i < params.length; i++) {
      t = t.replace('{' + i + '}', params[i])
    }
  }
  return t
}

export const setTranslations = async (t: Translations) => {
  translations = t
}

const defaultTranslations = {
  AddAllItemsToCart: 'Add all items to cart',
  addAllItemsToQuote: 'Add all item to My List',
  addListToCart: 'Add list to Cart',
  addProductToList: 'Share with Sales Rep',
  address: 'Address',
  addToCart: 'Add to Cart',
  addToList: 'Save to My List',
  addToQuote: 'Add to Quote',
  adyenFormCharacterError:
    'The string must only contain alphanumeric characters',
  adyenFormLettersError: 'The string must only contain letters',
  agreementTerms: 'I have read and accepted the terms',
  apply: 'Apply',
  back: 'Back',
  bookMeeting: 'Book a Meeting',
  call: 'Call',
  campaign: 'Campaign',
  campaignCodePlaceholder: 'Do you have a campaigncode?',
  campaigncodevalidator: 'The campaign code used is invalid',
  campaignOrGiftCardCodeInvalid:
    'You discount code does not validate. Please reload the page and try again. If the error persist please contact Eleiko customer service and state the discount code and what products you have added to the cart',
  careof: 'Careof',
  cart: 'Cart',
  cartcontainsproducts: 'Cart has no products',
  changecountry: 'Change country',
  chatWhatsApp: 'Chat on WhatsApp',
  checkout: 'Checkout',
  checkoutWith: 'Checkout with',
  chooseBarQuestion: 'Which bar is right for you?',
  city: 'City',
  clearfilter: 'Clear filter',
  close: 'Close',
  closeMenu: 'Close Menu',
  comingSoon: 'Coming soon',
  compareBars: 'Compare bars',
  connectBestMatch: 'Connect me to the best match',
  consentNewsletters: 'Yes please, I would like to receive Eleiko newsletters',
  countryNotification:
    'You are now visiting Eleiko {0}. Select your country to see content specific to your location and shop online.',
  contactSeller: 'Contact seller',
  contactUs: 'Contact Us',
  continue: 'Continue',
  continueBrowsing: 'Continue Browsing',
  continueShopping: 'Continue Shopping',
  country: 'Country',
  customercategory: 'Customer category',
  customerinformation: 'Customer Information',
  deliveryaddress: 'DeliveryAddress:',
  deliveryCost: 'Delivery Cost',
  deliveryMethod: 'Delivery Method',
  deliverymethodalidator: 'The current delivery method used is not allowed',
  deliveryDefaultDelivery: 'Delivery',
  deliveryWarehousePickup: 'Pickup in Warehouse',
  pickupAddress: 'Pickup Address',
  details: 'Details',
  discontinued: 'Discontinued',
  discount: 'Discount',
  discountCode: 'Discount Code',
  dotButton: 'Dot Button',
  downloads: 'Downloads',
  eleikomessage: 'Any message to Eleiko?',
  email: 'Email',
  emptyCart: 'Your cart is empty.',
  extraaddress: 'Extra address',
  exclVat: 'Excl VAT',
  exportCountriesText:
    'Please note that the delivery takes place according to CIP and customs costs are not included in the price.',
  favorites: 'My List',
  favoritesGeneralError: 'Error occurred while trying to send quote',
  features: 'Features',
  filter: 'Filter',
  financingOptions: 'Financing Options',
  firstname: 'First name',
  fixingtheproblem: 'We are working on fixing the problem, Be back soon',
  forShoppingOnline: 'For Shopping Online',
  forShoppingOnlineText: 'Share your list to any email or add items to cart.',
  fraudError:
    'We have a problem to verify your process your payment information. Please contact Eleiko customer service. Error code: 472',
  freeGift: 'Free Gift',
  from: 'From',
  galleryThumbnail: 'Gallery Thumbnail',
  generalError:
    'An error ocurred, please reload the page and try again. If the error persist. Please contact Eleiko customer service',
  getEleikoProductUpdates: 'Get Eleiko Product Updates',
  giftCard: 'Gift Card',
  giftCardIsUsed: 'Gift card code is already used',
  goToCart: 'Go to Cart',
  hide: 'Hide',
  inclVat: 'Incl VAT',
  invalidAddress: 'Some address information are invalid',
  invalidEmail: 'Invalid Email',
  invalidPhone: 'Invalid Phone',
  invalidUserInfo: 'Some user information are invalid',
  items: 'Items',
  lastname: 'Last name',
  limitedEdition: 'Limited edition',
  londonDynamics: 'London Dynamics',
  longDeliveryWarning:
    'The following items have longer delivery time than usual and will affect your total delivery time:',
  messageViaSite: 'Message via Eleiko.com',
  moreInfo: 'More info',
  myAccount: 'My Account',
  name: 'Name',
  newArrival: 'New arrival',
  next: 'Next',
  notifyMe: 'Notify me',
  notifysuccess:
    'Thank you! We will send you an email as soon as the article is in stock.',
  notShippingToYourCountry:
    'Sorry we are not shipping to your country at the moment',
  ok: 'Ok',
  okayGotIt: 'Okay, got it!',
  openMenu: 'Open Menu',
  openingHours: 'Opening hours',
  orderContainsPreorderDays:
    'Contains pre-order items. Shipped within {0} days',
  orderContainsPreorderMonths:
    'Contains pre-order items. Shipped within {0} months',
  orderContainsPreorderWeeks:
    'Contains pre-order items. Shipped within {0} weeks',
  ordernumber: 'Order #',
  orderSummary: 'Order Summary',
  orderTotal: 'Order Total',
  outOfStock: 'Out of Stock',
  organisationName: 'Company Name',
  pagenotfound: 'Page not found',
  pagenotfoundmessage: 'The page you requested could not be found',
  pages: 'Pages',
  pairmessage: 'Sold in pairs',
  paymentMethod: 'Payment Method',
  paymentmethodvalidator: 'The current payment method used is not allowed',
  pcsmessage: 'Sold individually',
  phone: 'Phone',
  preorder: 'Pre-order',
  preorderDays: 'Pre-order now. Shipped within {0} days',
  preorderMonths: 'Made to order. Shipped within {0} months',
  preorderWeeks: 'Preorder now. Shipped within {0} weeks',
  previous: 'Previous',
  priceFrom: 'Price from',
  productAdded: 'Added to cart!',
  pickupInWarehouseConfirmationMessage:
    'We will contact you through email when your order is ready for pickup.',
  productisavailableforsale: 'Some products are no longer available for sale',
  productnotfoundmessage: 'The product {0} you selected could not be found',
  productPricesMayVary:
    'Our products and prices may vary from country to country.',
  products: 'Products',
  productsareinstock:
    'The following products are out of stock or low in remaining stock: ',
  productSheet: 'Product sheet',
  quantityLimit: 'Out of stock - quantity updated to remaining stocks',
  quoteSuccessMessage:
    'Thank you for sending your wish list to our Sales representatives. We will be in touch shortly. The articles are still available in your wish list.',
  raiseTheBar: 'Raise the Bar',
  recipientEmail: 'recipient@mail.com',
  region: 'Region',
  relatedProducts: 'Related Products',
  remove: 'Remove',
  requestQuote: 'Request a Quote',
  requestQuoteText:
    'A sales rep will get back to you shortly and support you through your project.',
  required: 'Required',
  requiredField: 'Please enter your {0}',
  requiredOr: 'Either {0} or {1} has to be filled in',
  resultsFor: 'Results for',
  sale: 'Sale',
  salestax: 'Sales Tax',
  salesTaxError: 'An error occurred when trying to calculate sales tax.',
  saveFavorites: 'Save your favorites',
  saveFavoritesText: 'Bookmark items for shopping or to request a quote.',
  saveFavoritesTextNonShop: 'Bookmark items to request a quote.',
  search: 'Search',
  searchNoResult:
    'No resuls found, try to refine your search or clear all filters',
  showOnMap: 'Show on map',
  select: 'Select',
  selectCountryAndLanguage: 'Select your shipping country and site language:',
  selectdropdown: '- Select {0} -',
  selectPaymentMethod: 'Select Payment method',
  selectDeliveryMethod: 'Select Payment method',
  send: 'Send',
  sendquotation: 'Send',
  sendquotationlist: 'Send quotation list to sales',
  sendquote: 'Send quotation list to sales',
  sendToEmail: 'Send to Email',
  sendToSalesRep: 'Send to sales rep',
  servererror: '500: Server error',
  serviceParts: 'Service & Parts',
  setmessage: 'Sold in sets',
  sessionExpired:
    'You have been inactive for too long, please add your products to cart again.',
  share: 'Share',
  shareFavorites: 'Share with Sales Rep',
  shareFavoritesHeading: 'Our team of strength experts is here to help',
  shareFavoritesText:
    'Add products to your wish list and share them with our team. We will be in touch shortly and support you through your project.',
  sharelist: 'Share wish list',
  shareyourlist: 'Share your list',
  sharelistsent: 'Your wish list has been sent',
  shippingAndTaxesInfo: 'Shipping and taxes calculated at checkout.',
  showMore: 'Show more',
  sizechart: 'Size Chart',
  somethingwentwrong: 'Sorry, something went wrong',
  sort: 'Sort',
  'sort._score.desc': 'Relevance',
  'sort.bp_name.asc': 'Name A-Z',
  'sort.bp_name.desc': 'Name Z-A',
  'sort.date.asc': 'Date Old to New',
  'sort.date.desc': 'Date New to Old',
  'sort.price.asc': 'Price Low to High',
  'sort.price.desc': 'Price High to Low',
  'sort.recommended.asc': 'Popular products',
  'sort.title.asc': 'Name Z-A',
  'sort.title.desc': 'Name A-Z',
  specifications: 'Specifications',
  state: 'State',
  selectLocation: 'Select Location',
  stateOrProvince: 'State or province',
  stateOrProvinceError: 'State or province is required!',
  storyReadingTime: '{0} min read',
  streetaddress: 'Street address',
  submit: 'Submit',
  suggestions: 'Suggestions',
  termsAndConditions: 'Terms & Conditions',
  toRequestQuote: 'To Request a Quote',
  total: 'Total',
  threeDConfiguration: '3D Configuration',
  thirtyDayLowestPrice: 'Lowest price in the last 30 days',
  upsellTitle: 'Offers for you',
  validationcountrynotallowed: 'Current country is not allowed for purchase',
  validationinvalidgiftcard: 'Invalid gift card code',
  validationoutofstock: 'An item in the order is out of stock',
  vat: 'VAT',
  viewAll: 'View all',
  viewCart: 'View Cart',
  viewFavorites: 'View Favorites',
  warranty: 'Warranty',
  warrantyText: 'Warranty text',
  writeReview: 'Write a review',
  workwithsales: 'Work with Sales Rep',
  yourname: 'Your Name',
  youtubeVideo: 'Youtube Video',
  zip: 'Zip',
  zipError: 'Invalid zip code',
  pdf_additionalFallback: 'Additional Cost',
  pdf_amount: 'Amount',
  pdf_bank: 'Bank',
  pdf_quote: 'Quote',
  pdf_quoteId: 'Quote ID',
  pdf_contactEmail: 'Contact Email',
  pdf_contactPerson: 'Contact Person',
  pdf_customer: 'Customer',
  pdf_customerId: 'Customer ID',
  pdf_totalDiscount: 'Discount',
  pdf_quoteDate: 'Quote Date',
  pdf_quoteExpires: 'Quote Expires',
  pdf_deliveryAddress: 'Delivery Address',
  pdf_deliveryMethod: 'Delivery Method',
  pdf_deliveryTerms: 'Delivery Terms',
  pdf_eInvoice: 'E-invoice',
  pdf_installation: 'Installation',
  pdf_insurance: 'Insurance',
  pdf_invoiceAddress: 'Invoice Address',
  pdf_packing: 'Wrapping & Packing',
  pdf_paymentAndBank: 'Payment & Bank',
  pdf_paymentTerms: 'Payment Terms',
  pdf_xProducts: '{0} product(s)',
  pdf_currentPage: 'Page {0} of {1}',
  pdf_quantity: 'Qty',
  pdf_unitPrice: 'Unit Price',
  pdf_price: 'Price',
  pdf_articleId: 'Art. nr',
  pdf_wrappingAndPacking: 'Wrapping and Packing',
  pdf_shipping: 'Shipping',
  pdf_netAmount: 'Net Amount',
  pdf_salesTax: 'Sales Tax',
  pdf_salesTaxCA: 'Export Fee',
  pdf_subTotal: 'Subtotal',
  pdf_totalAmount: 'Total Amount',
  pdf_vat: 'VAT',
  pdf_contactSales: 'Your Sales Contact',
  pdf_pricereduction: 'Price Reduction',
}

let translations: Translations = defaultTranslations

export default translate
