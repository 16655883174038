import React, { MouseEventHandler } from 'react'
import Link from 'components/ui/Link'
import cn from 'classnames'
import { CheckIcon } from '@heroicons/react/24/solid'
import { CommonSectionSettingsColorThemeEnum } from 'framework/strapi/types'
import Spinner from '../Spinner'
import sleep from 'utils/sleep'

export type LoadingState = 'success' | 'loading' | 'error' | ''

export const setLoadingState = async (
  promiseFn: () => Promise<any>,
  setState: (state: LoadingState) => any,
) => {
  setState('loading')
  try {
    try {
      await promiseFn()
      return setState('success')
    } catch {
      return setState('error')
    }
  } finally {
    await sleep(1500)
    setState('')
  }
}

type ButtonLayout = 'button' | 'text'

export type ButtonSize = 'default' | 'sm' | 'sm-md' | 'lg'
type ButtonColor = 'primary' | 'secondary' | 'primaryInvert' | 'secondaryFill'

interface Props {
  href?: string
  id?: string
  type?: 'button' | 'submit' | 'reset'
  onClick?: MouseEventHandler<any>
  inGroup?: boolean
  layout?: ButtonLayout
  size?: ButtonSize
  color?: ButtonColor
  colorTheme?: CommonSectionSettingsColorThemeEnum
  icon?: string
  iconComponent?: React.ElementType
  active?: boolean
  state?: LoadingState
  disabled?: boolean
  children?: React.ReactNode
  className?: string
}

const base =
  'outline-none inline-flex justify-center items-center gap-x-2 cursor-pointer transition duration-200 font-bold'

const buttonBase = cn(
  base,
  'relative rounded-full border-solid border-4 text-center whitespace-nowrap align-middle',
)

const spanBase = 'inline-flex gap-x-2 items-center'

// Button sizes
// eslint-disable-next-line no-unused-vars
const sizeStyles: { [key in ButtonSize]: { button: string; span: string } } = {
  default: {
    button: 'px-7 xl:px-8 text-[15px]',
    span: 'leading-[38px] xl:leading-[48px] mt-[-2px]',
  },
  sm: { button: 'px-5 text-[13px]', span: 'leading-[30px] mt-[-2px]' },
  'sm-md': {
    button: 'px-5 xl:px-7 text-[13px] xl:text-[15px]',
    span: 'leading-[30px] xl:leading-[38px] mt-[-2px]',
  },
  lg: {
    button: 'min-w-[160px] w-full sm:w-auto px-8 text-[15px]',
    span: 'leading-[48px] mt-[-2px]',
  },
}

const colorStyles = (
  dark: boolean,
  inGroup: boolean,
  // eslint-disable-next-line no-unused-vars
): { [key in ButtonColor]: string } => ({
  primary: cn(
    'border-transparent hover:opacity-75',
    dark ? 'bg-white text-primary' : 'bg-primary text-white',
  ),
  secondary: cn(
    'bg-transparent hover:opacity-75',
    dark ? 'border-white text-white' : 'border-primary text-primary',
  ),
  primaryInvert: cn('border-transparent', {
    'bg-white text-primary': dark,
    'hover:bg-primary hover:text-white': dark && !inGroup,
    'group-hover:bg-primary group-hover:text-white': dark && inGroup,
  }),
  secondaryFill: cn({
    'border-primary bg-transparent text-primary': !dark,
    'border-white text-white': dark,
    'hover:border-transparent hover:bg-primary hover:text-white':
      !dark && !inGroup,
    'group-hover:border-transparent group-hover:bg-primary group-hover:text-white':
      !dark && inGroup,
    'hover:bg-white hover:text-primary': dark && !inGroup,
    'group-hover:bg-white group-hover:text-primary': dark && inGroup,
  }),
})

const Button = React.forwardRef<any, Props>(
  (
    {
      id,
      href,
      type,
      onClick,
      inGroup = false,
      layout = 'button',
      colorTheme,
      size = 'default',
      color = 'primary',
      iconComponent: IconComponent,
      active,
      state,
      disabled,
      children,
      className,
    },
    ref,
  ) => {
    const dark = colorTheme === 'Dark'

    const colorStyle = colorStyles(dark, inGroup)[color]
    const sizeStyle = sizeStyles[size]
    const css = {
      button: cn(buttonBase, sizeStyle.button, colorStyle, {
        'opacity-50 pointer-events-none': disabled,
      }),
      span: cn(spanBase, sizeStyle.span),
      icon: 'w-6 h-6',
    }

    if (layout === 'text') {
      css.button = cn(
        base,
        'w-auto text-[15px] hover:opacity-75',
        dark ? 'text-white' : 'text-primary',
      )
      css.icon = cn('w-6 h-6 duration-1000', { 'text-accent': active })
    }

    if (state) {
      css.span = cn(css.span, 'invisible')
    }

    if (!href)
      return (
        <button
          id={id}
          type={type}
          ref={ref}
          onClick={onClick}
          className={cn(css.button, className)}
          disabled={disabled}
        >
          {IconComponent && <IconComponent className={css.icon} />}
          <span className={css.span}>{children ? children : '...'}</span>
          {state && (
            <div className="absolute inline-block text-white -translate-x-1/2 -translate-y-1/2 position top-1/2 left-1/2">
              {state === 'loading' && <Spinner className="w-6 h-6" />}
              {state === 'success' && <CheckIcon className="w-6 h-6" />}
            </div>
          )}
        </button>
      )

    return (
      <Link
        id={id}
        href={href}
        onClick={onClick}
        className={cn(css.button, className)}
      >
        {IconComponent && <IconComponent className={css.icon} />}
        <span className={css.span}>{children ? children : '...'}</span>
      </Link>
    )
  },
)

Button.displayName = 'Button'

export default Button
